import { Match, Switch } from 'solid-js';
import { useIsEnabled } from '@troon/analytics';
import { useUser } from '../../providers/user';
import { LoggedIn } from './_variants/logged-in';
import { LoggedOut } from './_variants/logged-out';
import type { RouteDefinition } from '@solidjs/router';

export default function Home() {
	const user = useUser();
	const isDotCom = useIsEnabled('dot-com-takeover');

	return (
		<>
			<Switch fallback={<LoggedIn />}>
				<Match when={user() !== null && isDotCom}>
					<LoggedIn />
				</Match>
				<Match when={user() === null && isDotCom}>
					<LoggedOut />
				</Match>
			</Switch>
		</>
	);
}
export const route = { info: { hasHero: true } } satisfies RouteDefinition;
