import { For, Show, Suspense, Switch, useContext, Match, createSignal } from 'solid-js';
import { Button, Form, Link, safeTransition, SquareLogo, TextLink } from '@troon/ui';
import { IconArrowRightMd, IconChevronRight, IconUserCircle } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { OldCardCtx } from '../../../../providers/card';
import { getConfigValue } from '../../../../modules/config';
import { Content } from '../../../../components/content';
import { FacilityCard } from '../../../../components/facility/card';
import { MobileUpsellBanner } from '../../../../components/mobile-upsell';
import { TeeTimeSearch } from '../../../../components/tee-time-search';
import { useUser } from '../../../../providers/user';
import { Grid } from '../../../../components/layouts/grid';
import { SupportButton } from '../../../../components/support';
import { FrequentlyAskedQuestions } from '../../../../components/faqs';
import { useUtmParams } from '../../../../providers/utm';
import { gql, mutationAction, useMutation } from '../../../../graphql';
import { Hero } from '../../../../components/hero/photo';
import { LocationCard } from './components/location-card';
import type { FacilityCardFragment } from '../../../../graphql';
import type { ParentProps, JSX } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

const maxStates = 6;

export default function OldTroonCard() {
	const card = useContext(OldCardCtx);
	const user = useUser();
	const utm = useUtmParams();
	const [showAllLocations, setShowAllLocations] = createSignal(false);

	const optOutAction = useMutation(optOut);

	return (
		<>
			<Suspense>
				<Title>{card()?.card.group.name} | Troon Rewards</Title>
			</Suspense>

			<Hero src={`https://${getConfigValue('MAP_HOST')}/assets/images/troon-north.jpg`}>
				<div class="flex flex-col gap-2 pb-28 pt-16 text-center text-white md:gap-4">
					<h1 class="text-3xl font-semibold leading-tight sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-snug">
						{card()?.card.group.name}
					</h1>
					<p class="text-base">Explore and book discounted tee times at participating Troon courses.</p>
				</div>
			</Hero>

			<Content>
				<div class="relative -top-24 z-30 -mb-12 rounded bg-white p-4 shadow-lg">
					<Switch>
						<Match when={user()?.me.card?.id === card()?.card.group.id}>
							<TeeTimeSearch card={card()?.card.group.id} />
						</Match>
						<Match when={user()}>
							<div class="flex flex-row flex-wrap items-center justify-between gap-4 lg:flex-nowrap">
								<div class="flex shrink flex-row items-center gap-4">
									<IconUserCircle class="shrink-0 self-start text-3xl text-brand lg:self-center" />
									<p class="shrink grow">
										You do not have an active Troon Card subscription associated with your account. If you think this is
										a mistake, contact support.
									</p>
								</div>
								<div class="grow lg:shrink lg:grow-0">
									<SupportButton appearance="secondary" class="w-full">
										<span class="text-nowrap">Contact support</span>
									</SupportButton>
								</div>
								<div class="grow lg:shrink lg:grow-0">
									<Button as={Link} href="/tee-times">
										<span class="text-nowrap">Book without card</span>
									</Button>
								</div>
							</div>
						</Match>
						<Match when={!user()}>
							<div class="flex flex-row flex-wrap items-center justify-between gap-4 xl:flex-nowrap">
								<div class="flex flex-row items-center gap-4">
									<IconUserCircle class="shrink-0 self-start text-3xl text-brand lg:self-center" />
									<p class="shrink grow">
										Log in to your Troon Rewards account to book tee times using the {card()?.card.group.name} benefits.
									</p>
								</div>
								<div class="grow lg:shrink lg:grow-0">
									<Button as={Link} href={`/auth/?redirect=/card/${card()?.card.group.id}`} state={{ fromCard: true }}>
										Log in
									</Button>
								</div>
							</div>
						</Match>
					</Switch>
				</div>
			</Content>

			<Content class="flex flex-col gap-8 md:gap-16">
				<Show when={utm().campaign === 'old-troon-card'}>
					<div class="flex flex-wrap justify-start gap-4 rounded bg-brand-100 p-4 text-brand sm:gap-2 lg:flex-nowrap lg:items-center lg:justify-stretch">
						<SquareLogo class="size-8 shrink-0" />
						<p class="grow">Welcome to our new and improved booking experience for Troon Cardholders.</p>
						<Form action={optOutAction} class="shrink">
							<input
								type="hidden"
								name="slug"
								value={`troon-card-${card()?.card.group.id}${utm()?.content?.includes('-single') ? '-single' : ''}`}
							/>
							<Button type="submit" appearance="transparent" class="relative -start-4 shrink normal-case lg:start-0">
								Use old booking experience
								<IconArrowRightMd />
							</Button>
						</Form>
					</div>
				</Show>

				<Section>
					<h2 class="text-xl font-semibold md:text-2xl">Courses by location</h2>
					<ul class="mb-4 grid snap-x grid-cols-2 gap-4 overflow-auto sm:grid-cols-3 md:flex-wrap md:overflow-hidden lg:grid-cols-6">
						<For each={showAllLocations() ? card()?.card.states : card()?.card.states.slice(0, maxStates)}>
							{(state) => (
								<li
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="aspect-[16/10] min-w-32 shrink grow snap-start md:min-w-0"
								>
									<LocationCard state={state} cardGroupId={card()!.card.group.id} />
								</li>
							)}
						</For>
					</ul>
					<Show when={!showAllLocations() && (card()?.card.states.length ?? 0) > maxStates}>
						<div class="flex items-center justify-center">
							<Button
								appearance="secondary"
								class="shrink grow-0"
								onClick={() => {
									safeTransition(() => {
										setShowAllLocations(true);
									});
								}}
							>
								See all locations
							</Button>
						</div>
					</Show>
				</Section>

				<Show when={card()?.card.stateFacilities}>
					{(state) => (
						<Section>
							<SectionHeader
								title={
									<>
										{state().state.name} course{state().facilities.length !== 1 ? 's' : ''}
									</>
								}
							>
								<Button
									as={Link}
									href={`/card/${card()?.card.group.id}/courses/${state().state.id}`}
									appearance="transparent"
									class="hidden md:block"
								>
									See all <span class="sr-only">{state().state.name} courses</span> <IconChevronRight />
								</Button>
							</SectionHeader>
							<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<For each={state().facilities.slice(0, 3) as Array<FacilityCardFragment>}>
									{(facility) => (
										<li>
											<FacilityCard facility={facility} linkToTeeTimes />
										</li>
									)}
								</For>
							</ul>
							<div class="flex items-center justify-center md:hidden">
								<div>
									<Button
										as={Link}
										href={`/card/${card()?.card.group.id}/courses/${state().state.id}`}
										appearance="secondary"
									>
										See all <span class="sr-only">{state().state.name} courses</span> <IconChevronRight />
									</Button>
								</div>
							</div>
						</Section>
					)}
				</Show>

				<MobileUpsellBanner variant="xl-banner" />

				<Grid>
					<div class="col-span-1 lg:col-span-5">
						<h2 class="mb-4 text-3xl font-semibold md:text-4xl">Frequently asked questions</h2>
						<p class="mb-6 max-w-md">Got questions? We’ve got answers.</p>
						<div>
							<SupportButton class="shrink" appearance="secondary">
								Contact support
							</SupportButton>
						</div>
					</div>

					<div class="col-span-1 rounded border border-neutral lg:col-span-7">
						<FrequentlyAskedQuestions content={faqs} />
					</div>
				</Grid>
			</Content>
		</>
	);
}

function SectionHeader(props: ParentProps<{ title: JSX.Element }>) {
	return (
		<div class="flex flex-row flex-wrap items-center justify-between">
			<h2 class="text-xl font-semibold md:text-2xl">{props.title}</h2>
			<div>{props.children}</div>
		</div>
	);
}

function Section(props: ParentProps) {
	return <div class="flex flex-col gap-4">{props.children}</div>;
}

const faqs: Record<string, () => JSX.Element> = {
	'What benefits do Troon Cards provide?': () => (
		<>
			<p>
				Troon Cards provide Cardholders and guest(s) with rates that are up to 50% off at participating Troon
				facilities. TwoSome Cardholders can bring up to one guest and FourSome Cardholders may bring up to three guests.
				Guest(s) are not required.
			</p>
			<ul class="flex list-disc flex-col gap-2 ps-4">
				<li>
					NEW in 2024 – Extended Booking Window: Troon Card tee times can now be reserved 78 hours in advance (example:
					6pm on Monday for a Friday morning tee time)
				</li>
				<li>
					Best Rate Guarantee: Troon Card’s Best Rate Guarantee allows Cardholders to book any standard{' '}
					<strong>ONLINE RATE</strong> and receive 15% off their golf fee at check in. This benefit is in effect outside
					the 3 day advance booking window! Not valid on prepaid rounds. Cardholder and guests must qualify for online
					rate. Rate must be for same day and time. Excludes actual Troon Card rate.
				</li>
				<li>
					“2 for 1” golf offers: Included with the purchase of any 2024 Troon Card, is a one-time “2 for 1” at the Troon
					Card rate (five redemptions each for State and Regional Troon Cards and ten redemptions for the National Troon
					Card).
				</li>
				<li>
					The advance booking of the one-time “2 for 1” varies based on time of year (
					<TextLink href="https://www.troon.com/troon-golf-cards/2for1-tandc/">
						Click for Flexible “2 for 1” Calendar
					</TextLink>
					).
				</li>
				<li>
					Birthday Round: Cardholders receive 25% off Troon Card rate on the day of their Birthday. Must provide valid
					drivers license at check in to redeem. 25% off does not apply to Cardholder guest (s).
				</li>
				<li>
					Replay Rates: Cardholders and qualifying guests may enjoy replay rounds from as little as $25 per player.
					Rounds may be booked at the conclusion of the first round upon course availability
				</li>
				<li>25% off Golf Club Rental Fees for Cardholder at Participating Courses</li>
				<li>10% off Merchandise at Participating Golf Shops and Participating Golf Course Restaurants</li>
				<li>Complimentary Skills Clinics</li>
				<li>Customized Experience Packages</li>
				<li>Troon Card E-News: Monthly offers exclusively for Troon Cardholders</li>
				<li>
					<TextLink
						href="https://www.facebook.com/groups/275222245991414/402166486630322/?ref=notif%25ACif_t%3Dgroup_activity"
						target="_blank"
						rel="noopener"
					>
						Troon Card Facebook Group
					</TextLink>
				</li>
				<li>
					Book Tee Times anytime – phone or <TextLink href="https://www.troon.digital/">TroonCardTeeTimes.com</TextLink>
				</li>
			</ul>
		</>
	),
	'How far in advance can tee times be reserved?': () => (
		<>
			<p>
				Reserve tee times by phone or by visiting{' '}
				<TextLink href="https://www.troon.com/club-directory/">TroonCardTeeTimes.com</TextLink>, 3+ days in advance
				(example: 6pm on Monday for a Friday morning tee time) to receive Troon Card rates up to 50% off standard rates
				OR book any standard <strong>ONLINE RATE</strong> outside of the three day window and receive 15% off your golf
				fee at check in. Visit{' '}
				<TextLink href="https://www.troon.com/troon-golf-cards/troon-card-restrictions-and-terms/">
					terms and conditions
				</TextLink>{' '}
				for details. “2 for 1” Golf Offers may be booked based on the{' '}
				<TextLink href="https://www.troon.com/troon-golf-cards/2for1-tandc/">Flexible “2 for 1” Calendar</TextLink>.
				Some restrictions apply.
			</p>
		</>
	),
	'How and when do I use my "2 for 1" golf offers?': () => (
		<>
			<p>
				Log into your account at <TextLink href="https://troon.com/account/login">TroonRewards.com</TextLink>. On the
				top right of the page there is a link named “Troon Card Offers”. That link will take you to a chart that will
				show each club you are entitled to your one time “2 for 1”. There is no dedicated “2 for 1” booking portal. You
				are not required to present a certificate when you check in for your tee time. Simply book your tee time based
				upon the restrictions outlined in the following calendar{' '}
				<TextLink href="https://www.troon.com/troon-golf-cards/2for1-tandc/">Click for Calendar</TextLink>
			</p>
		</>
	),
	'How many greens fees does my "2 for 1" cover?': () => (
		<>
			<p>
				TwoSome and FourSome cards cover two greens fees for the price of one. If you have a group of two you pay one
				greens fee. If you have a group of three you pay two greens fees. If you have a group of four you pay three
				greens fees.
			</p>
		</>
	),
	'Are there restrictions on the "2 for 1” golf offers?': () => (
		<>
			<p>
				(<TextLink href="https://www.troon.com/troon-golf-cards/2for1-tandc/">See Flexible “2 for 1” Calendar</TextLink>
				). Valid beginning November 1, 2023 through December 31, 2024.
			</p>
		</>
	),
	'When can I start using my Troon Card?': () => (
		<>
			<p>
				It will take up to 4 business days to process your Troon Card order. You will receive a welcome email with your
				Troon Card Number and a general overview.
			</p>
		</>
	),
	'How long is my Troon Card valid for?': () => (
		<>
			<p>All 2024 Troon Cards are valid for use starting November 1, 2023, through December 31, 2024.</p>
		</>
	),
	'Can a card number have more than one name or a company name on it?': () => (
		<>
			<p>Card numbers are for individuals only.</p>
		</>
	),
	'Does the Cardholder need to be present at all times?': () => (
		<>
			<p>The Cardholder must be playing when the card number is being used. A picture ID is required at check-in.</p>
		</>
	),
	'Can I let another person use my card number?': () => (
		<>
			<p>Card numbers are non-transferable.</p>
		</>
	),
	'What is the cancellation policy on Troon Card tee times?': () => (
		<>
			<p>
				The cancellation policy is 24 hours in advance. Cancellations within this period may be subject to cancellation
				fees at the golf course’s discretion.
			</p>
		</>
	),
	'Will I receive a physical card?': () => (
		<>
			<p>The program is all-electronic, nothing physical is sent to you.</p>
		</>
	),
};

const optOutMutation = gql(`
	mutation optOutFacilityRedirect($slug: String!) {
		persistFacilityRedirect(facilitySlug: $slug, variation: "control") {
			redirectUrl
		}
	}`);

const optOut = mutationAction(optOutMutation, {
	onSuccess: async (res) => {
		if (res) {
			window.location.href = res.persistFacilityRedirect.redirectUrl;
		}
	},
});

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
