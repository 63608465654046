import { clientOnly } from '@solidjs/start';
import { Meta, Title } from '@solidjs/meta';
import { Show, Suspense } from 'solid-js';
import { Button, Heading, Link, TextLink } from '@troon/ui';
import { IconCircleCheck } from '@troon/icons';
import { useUser } from '../../providers/user';
import { Content } from '../../components/content';
import { TroonCardSubscriptionStatus } from '../../graphql';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

const StripeProvider = clientOnly(async () => ({ default: (await import('../../providers/stripe')).StripeProvider }));

export default function CheckoutLayout(props: RouteSectionProps) {
	const user = useUser();

	return (
		<Suspense>
			<Show
				when={
					user()?.activeTroonCardSubscription?.status === TroonCardSubscriptionStatus.Active &&
					user()?.activeTroonCardSubscription
				}
				fallback={<NoCurrentUserAccess {...props} />}
			>
				{(sub) => (
					<Content size="xsmall">
						<div class="flex flex-col gap-8">
							<div class="flex flex-col items-center gap-6 text-center">
								<IconCircleCheck class="text-8xl text-brand" />
								<Heading as="h1" size="h2">
									Your {sub().name} is already active!
								</Heading>

								<p>
									<TextLink href="/account/access">Manage your your {sub().name} plan</TextLink>.
								</p>

								<Button as={Link} href="/tee-times">
									Start golfing
								</Button>
							</div>
						</div>
					</Content>
				)}
			</Show>
		</Suspense>
	);
}

function NoCurrentUserAccess(props: RouteSectionProps) {
	return (
		<>
			<Meta name="robots" content="noindex" />
			<Title>Checkout | Troon Rewards</Title>
			<StripeProvider>{props.children}</StripeProvider>
		</>
	);
}

export const route = { info: { nav: 'minimal' } } satisfies RouteDefinition;
