import { For, Show, Suspense, createResource, createSignal } from 'solid-js';
import { useSearchParams } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { Button, Input, Label, TextField } from '@troon/ui';
import { IconCloseMd, IconSearchMagnifyingGlass } from '@troon/icons';
import { gql } from '../../graphql';
import { FacilityCard } from '../../components/facility/card';
import { Content } from '../../components/content';
import { PageHeader } from '../../components/page-header';
import { getSchemaString } from '../../modules/schema/schema';
import { breadcrumbs } from '../../modules/schema/breadcrumb-list';
import { webpage } from '../../modules/schema/webpage';
import { cachedQuery } from '../../graphql/cached-get';
import type { FacilityCardFragment } from '../../graphql';

export const route = {
	preload: () => {
		return getCourses({});
	},
};

export default function Courses() {
	const [params] = useSearchParams<{ q?: string }>();
	const [query, setQuery] = createSignal(params.q ?? '');
	const [facilities] = createResource(() => ({ query: query() }), getCourses, { deferStream: true });

	return (
		<Content>
			<Title>Book tee times at your favorite golf courses | Troon Rewards</Title>
			<script
				type="application/ld+json"
				innerText={getSchemaString([
					breadcrumbs(
						`/courses${query() ? `?q=${query()}` : ''}`,
						[
							{ name: 'Home', pathname: '/' },
							{ name: 'Courses', pathname: '/courses' },
						].filter(Boolean),
					),
					webpage(`/courses${query() ? `?q=${query()}` : ''}`, {
						title: 'Book tee times at your favorite golf courses | Troon Rewards',
					}),
				])}
			/>

			<div class="flex flex-col gap-8">
				<PageHeader title="Explore courses" subtext={<p>Book tee times online at your favorite Troon courses.</p>} />

				<TextField name="search">
					<Label class="sr-only">Find a course</Label>
					<Input
						onInput={(e) => setQuery(e.currentTarget.value)}
						prefixElement={<IconSearchMagnifyingGlass class="mx-1" />}
						suffixElement={
							<Show when={query()}>
								<Button appearance="transparent" class="pointer-events-auto p-2" onClick={() => setQuery('')}>
									<IconCloseMd />
									<span class="sr-only">Clear search</span>
								</Button>
							</Show>
						}
						class="appearance-none px-10"
						placeholder="Where do you want to play?"
						value={query()}
					/>
				</TextField>

				<Show when={query()}>
					<p class="text-3xl font-semibold">
						{facilities.latest?.length ?? '0'} course<Show when={facilities.latest?.length !== 1}>s</Show>
					</p>
				</Show>

				<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
					<Suspense>
						<For each={facilities.latest}>
							{(facility, index) => (
								<li>
									<FacilityCard
										disableFavorites
										facility={facility as FacilityCardFragment}
										level="h2"
										loading={index() <= 15 ? 'eager' : 'lazy'}
									/>
								</li>
							)}
						</For>
					</Suspense>
				</ul>
			</div>
		</Content>
	);
}

const listQuery = gql(`
query facilities {
  facilities {
    ...FacilityCard
  }
}`);

const searchQuery = gql(`
query searchFacilitiesList($query: String!) {
	facilities: searchFacilities(query: $query, includeGeocoded: true) {
		...FacilityCard
	}
}`);

const getCourses = async ({ query }: { query?: string }) => {
	if (!query || query.length < 3) {
		return (await cachedQuery(listQuery)({}))?.facilities ?? [];
	}

	try {
		const res = await cachedQuery(searchQuery)({ query });
		return res?.facilities ?? [];
	} catch {
		return [];
	}
};
