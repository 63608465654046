import { Meta, Title } from '@solidjs/meta';
import {
	Avatar,
	Details,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Picture,
	Summary,
	TextLink,
	TroonAccess,
} from '@troon/ui';
import { IconArrowRightMd, IconStarFilled } from '@troon/icons';
import { createSignal, For, Index } from 'solid-js';
import { createAsync } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { getConfigValue } from '../../modules/config';
import { Content } from '../../components/content';
import { AccessProducts } from '../../components/access-products';
import { ImageOverlay } from '../../components/image-overlay';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { Grid, GridHalf, GridThird } from '../../components/layouts/grid';
import { SupportButton } from '../../components/support';
import { FrequentlyAskedQuestions } from '../../components/faqs';
import type { RouteDefinition } from '@solidjs/router';
import type { JSX, ParentProps } from 'solid-js';

type Fac = {
	name: string;
	slug: string;
	metadata?: { address?: { city?: string | null; state?: string | null } | null } | null;
};
const locationColumns = 3;

export default function TroonCard() {
	const [open, setOpen] = createSignal(false);

	const data = createAsync(
		async () => {
			const data = await getData({});

			const compare = new Intl.Collator('en').compare;

			const byState = Object.entries(
				(data?.facilities.facilities ?? []).reduce(
					(memo, facility) => {
						const state = facility.metadata?.address?.state;
						if (!state) {
							return memo;
						}
						if (!(state in memo)) {
							memo[state] = [];
						}
						memo[state]!.push(facility);
						return memo;
					},
					{} as Record<string, Array<Fac>>,
				),
			).sort((a, b) => compare(a[0], b[0]));

			const chunkSize = Math.ceil(byState.length / locationColumns);
			const byStateChunks = Array.from({ length: locationColumns }, (_, i) =>
				byState.slice(i * chunkSize, (i + 1) * chunkSize),
			);

			return { featured: data?.featured, facilitiesByState: byStateChunks };
		},
		{ deferStream: true },
	);

	return (
		<>
			<Title>Troon Access | Exclusive savings at over 150 participating golf courses | Troon</Title>
			<Meta
				name="description"
				content="The ultimate way to experience Troon golf. Exclusive access to savings at over 150 participating Troon golf courses."
			/>

			<div class="relative flex min-h-64 flex-col items-center bg-white pt-12 sm:min-h-72 md:min-h-96">
				<div
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="absolute inset-0 max-h-[560px] md:max-h-[800px]"
				>
					<Picture
						src={`${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`}
						alt=""
						sizes={[
							[480, 500],
							[960, 640],
							[1600, 1024],
						]}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="size-full bg-center object-cover brightness-[.65]"
						loading="eager"
					/>
					<ImageOverlay class="absolute -bottom-1" />
				</div>
				<div class="relative z-20 overflow-visible">
					<div class="-mb-10 flex min-h-96 flex-col items-center justify-center gap-4 px-2 text-white sm:px-0">
						<h1 class="max-w-xl text-center text-3xl font-semibold md:text-5xl">
							<TroonAccess class="w-full min-w-64 max-w-96" />
							<span class="sr-only">Troon Access</span>
						</h1>
						<p class="max-w-xl text-center">
							The ultimate way to experience Troon. Exclusive access to savings at over 150 participating Troon courses.
						</p>
					</div>
					<Content size="small" class="z-30 overflow-visible text-neutral-950">
						<AccessProducts floating />
					</Content>
				</div>
			</div>

			<div class="flex flex-col gap-12 md:gap-24">
				<Content class="">
					<CenterContent>
						<Heading as="h2">Better benefits, more access</Heading>
						<p>
							Join the all-new Troon Access program to unlock exclusive savings at over 150 Troon courses including
							Kapalua, Troon North, and Gamble Sands.
						</p>
					</CenterContent>
					<div class="grid w-full grid-cols-3 gap-16">
						<Benefit>
							<svg aria-hidden class="size-16">
								<use href="#flag" />
							</svg>
							<Heading as="h3">Save every time you play</Heading>
							<p>Save 15% on unlimited rounds. Troon Access+ members enjoy up to 50% off tee times within 78 hours.</p>
						</Benefit>
						<Benefit>
							<svg aria-hidden class="size-16">
								<use href="#map" />
							</svg>
							<Heading as="h3">Access 150+ world-class courses</Heading>
							<p>Your benefits apply at all participating Troon courses around the U.S.</p>
						</Benefit>
						<Benefit>
							<svg aria-hidden class="size-16">
								<use href="#tag" />
							</svg>
							<Heading as="h3">Exclusive Troon Access Perks & Offers</Heading>
							<p>Enjoy additional benefits like free rental clubs*, partner offers, and savings on merchandise.</p>
						</Benefit>
						<div class="col-span-3 flex justify-center">
							<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
								<DialogTrigger appearance="primary" class="shrink grow-0">
									Get started <IconArrowRightMd />
								</DialogTrigger>
								<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
									<AccessProducts />
								</DialogContent>
							</Dialog>
						</div>
					</div>
				</Content>

				<div class="relative overflow-hidden bg-brand-700 text-white">
					<Picture
						alt=""
						src={`https://${getConfigValue('MAP_HOST')}/assets/images/map-base.png`}
						class="absolute inset-x-0 top-0 -z-0 w-full bg-top object-cover"
						loading="lazy"
						sizes={[
							[480, 198],
							[960, 396],
							[1280, 528],
						]}
					/>

					<Content class="relative z-0 mb-16">
						<For each={data()?.featured?.facilities}>
							{(feat, index) => (
								<Picture
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class={twJoin(
										'absolute -z-0 hidden aspect-[3/4] w-[300px] rounded xl:block',
										index() % 2 ? '-right-6 top-12 -rotate-6' : '-left-12 bottom-12 rotate-6',
									)}
									alt=""
									src={feat.metadata?.hero?.url}
									sizes={[[300, 400]]}
									loading="lazy"
								/>
							)}
						</For>
						<CenterContent>
							<Heading as="h2">150+ World-class courses.</Heading>
							<p>
								Troon Access members can use their benefits across the entire network of participating Troon courses.
								Search courses by state below:
							</p>
						</CenterContent>

						<div role="list" class="relative z-0 grid grid-cols-3 gap-x-8">
							<For each={data()?.facilitiesByState}>
								{(column) => (
									<div role="presentation" class="col-span-3 lg:col-span-1">
										<div role="presentation" class="divide-y divide-brand-600 border border-brand-600">
											<For each={column}>
												{(byState) => (
													<div role="listitem" class="text-nowrap">
														<Details class="bg-brand-700 p-4">
															<Summary>
																<Heading as="h3" size="h5">
																	{byState[0]}
																</Heading>
															</Summary>
															<ul class="flex flex-col gap-6">
																<For each={byState[1]}>
																	{(facility) => (
																		<li class="flex flex-col gap-1">
																			<Heading as="h4" size="p">
																				{facility.name}
																			</Heading>
																			<p class="text-sm">
																				{facility.metadata?.address?.city}, {facility.metadata?.address?.state}
																			</p>
																		</li>
																	)}
																</For>
															</ul>
														</Details>
													</div>
												)}
											</For>
										</div>
									</div>
								)}
							</For>
						</div>
					</Content>
					<ImageOverlay class="absolute -bottom-1" />
				</div>
			</div>

			<Content class="flex flex-col gap-y-16">
				<section>
					<CenterContent>
						<Heading as="h2" size="h2" class="text-center">
							Don’t just take our word for it
						</Heading>
					</CenterContent>
					<Grid>
						<GridThird>
							<Review rating={5}>
								<Quote>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
									tristique. Duis cursus, mi quis viverra ornare.
								</Quote>
								<Cite location="New York, NY">Billy I.</Cite>
							</Review>
						</GridThird>
						<GridThird>
							<Review rating={5}>
								<Quote>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
									tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero
									vitae erat.
								</Quote>
								<Cite location="New York, NY">Jeff M.</Cite>
							</Review>
						</GridThird>
						<GridThird>
							<Review rating={5}>
								<Quote>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum
									tristique. Duis cursus, mi quis viverra ornare.
								</Quote>
								<Cite location="New York, NY">Zach B.</Cite>
							</Review>
						</GridThird>
					</Grid>
				</section>

				<hr class="my-4 border-neutral-500 md:my-6" />

				<Grid>
					<GridHalf class="flex flex-col gap-6">
						<Heading as="h2" size="h2">
							Frequently asked questions
						</Heading>
						<p>You have questions, we have answers. Not seeing what you’re looking for?</p>
						<div>
							<SupportButton class="shrink" appearance="secondary">
								Contact support
							</SupportButton>
						</div>
					</GridHalf>

					<GridHalf border>
						<FrequentlyAskedQuestions content={faqs} />
					</GridHalf>
				</Grid>
			</Content>

			<svg width="0" height="0" class="hidden">
				<symbol fill="none" viewBox="0 0 60 60" id="tag">
					<path
						d="m11.9 19.1-.9 9.1V31c0 .6.3 1.1.6 1.6.3.6.8 1 1.7 2l13 13c2 2 3 3 4 3.3 1 .3 2.2.3 3.2 0 1.1-.4 2.1-1.3 4-3.3l10-10c2-1.9 3-2.9 3.3-4 .3-1 .3-2.1 0-3.1-.4-1.2-1.3-2.2-3.3-4.1l-13-13c-1-1-1.4-1.4-2-1.7A5 5 0 0 0 31 11h-2.7l-9 .9c-2.5.2-3.6.3-4.6.8a5 5 0 0 0-1.9 2c-.5.9-.6 2-.8 4.4Z"
						fill="#E6F2F3"
					/>
					<path d="M24.3 24.3a2.5 2.5 0 1 0-3.6-3.6 2.5 2.5 0 0 0 3.6 3.6Z" fill="#E6F2F3" />
					<path
						d="m11.9 19.1-.9 9.1V31c0 .6.3 1.1.6 1.6.3.6.8 1 1.7 2l13 13c2 2 3 3 4 3.3 1 .3 2.2.3 3.2 0 1.1-.4 2.1-1.3 4-3.3l10-10c2-1.9 3-2.9 3.3-4 .3-1 .3-2.1 0-3.1-.4-1.2-1.3-2.2-3.3-4.1l-13-13c-1-1-1.4-1.4-2-1.7A5 5 0 0 0 31 11h-2.7l-9 .9c-2.5.2-3.6.3-4.6.8a5 5 0 0 0-1.9 2c-.5.9-.6 2-.8 4.4h0Z"
						stroke="#027F88"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M24.3 24.3a2.5 2.5 0 1 0-3.6-3.6 2.5 2.5 0 0 0 3.6 3.6Z"
						stroke="#027F88"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</symbol>
				<symbol fill="none" viewBox="0 0 60 60" id="map">
					<path d="m52.5 45-15 7.5-15-7.5-15 7.5V15l15-7.5 15 7.5 15-7.5V45Z" fill="#E6F2F3" />
					<path
						d="M37.5 15v37.5m0-37.5 15-7.5V45l-15 7.5m0-37.5-15-7.5m15 45-15-7.5m0 0-15 7.5V15l15-7.5m0 37.5V7.5"
						stroke="#027F88"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</symbol>
				<symbol fill="none" viewBox="0 0 61 60" id="flag">
					<path
						d="M10.333 39.218c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
						fill="#E6F2F3"
					/>
					<path
						d="M10.333 52.5V39.218m0 0c14.546-11.374 25.455 11.373 40 0V10.783c-14.545 11.373-25.454-11.374-40 0v28.434Z"
						stroke="#027F88"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</symbol>
			</svg>
		</>
	);
}

function CenterContent(props: ParentProps) {
	return (
		<div class="relative z-0 mx-auto flex max-w-xl flex-col gap-4 py-16 text-center md:py-24">{props.children}</div>
	);
}

function Benefit(props: ParentProps) {
	return <div class="col-span-3 flex flex-col items-center gap-6 text-center lg:col-span-1">{props.children}</div>;
}

const accessQuery = gql(`
query accessPage {
	facilities: facilitiesV2 {
		facilities {
			name
			slug
			metadata {
				address {
					city
					state
				}
			}
		}
	}
	featured: facilitiesV2(limit: 2, featured: true) {
		facilities {
			metadata {
	 			hero {
		 			url
		 		}
			}
		}
	}
}`);

const getData = cachedQuery(accessQuery);

const faqs: Record<string, () => JSX.Element> = {
	'How is Troon Access different from the Troon Card?': () => (
		<p>
			We’ve taken your feedback and made our new program more simple and cost effective. As a Troon Access cardholder,
			regardless of your tier, you will enjoy tee time savings at all 150+ participating Troon courses, at a fraction of
			the cost of a National Troon Card.
		</p>
	),
	'How long are my Troon Access benefits valid?': () => (
		<p>
			Unlike previous versions of the Troon Card, your Troon Access benefits are valid for 1 year after the purchase
			date (i.e. benefits are no longer tied to a calendar year).
		</p>
	),
	'Will guests get my Troon Access benefits?': () => (
		<p>
			The Troon Access program is specific to the purchasing cardholder. To access the benefits of Troon Access, you
			must be a Troon Access or Troon Access+ member.
		</p>
	),
	'Is my Troon Access login the same as my Troon Rewards login?': () => (
		<p>
			Finally, yes! Unlike the Troon Card, you no longer have a separate login to access your benefits. Troon Reward
			points will be rewarded where applicable.
		</p>
	),
	'Where can I book tee times with my Troon Access benefits?': () => (
		<p>
			In our all-new <TextLink href="/tee-times">booking experience</TextLink>, you can access your tee time savings,
			compare tee time availability at multiple courses,set tee time alerts and more! Access tee times on the go using
			our new mobile app (link to app).
		</p>
	),
};

function Review(props: ParentProps<{ rating: number }>) {
	return (
		<div class="flex flex-col gap-4 rounded border border-neutral p-6">
			<div>
				<Index each={[...Array(props.rating).keys()]}>
					{() => (
						<IconStarFilled
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="text-[#FFBE5D]"
						/>
					)}
				</Index>
			</div>
			{props.children}
		</div>
	);
}

function Quote(props: ParentProps) {
	return (
		<blockquote
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="before:content-[open-quote] after:content-[close-quote]"
		>
			{props.children}
		</blockquote>
	);
}

function Cite(props: { children: string; location: string }) {
	return (
		<div class="flex w-full flex-row items-center gap-x-2">
			<Avatar
				class="size-10 text-neutral-600"
				firstName={props.children.split(' ')[0] ?? ''}
				lastName={props.children.split(' ')[1] ?? ''}
			/>
			<p class="flex flex-col gap-px">
				<span class="text-sm font-semibold">{props.children}</span>
				<span class="text-xs">{props.location}</span>
			</p>
		</div>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
